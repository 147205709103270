Vue.component('appComponent', {
    props: ['categoryString','categoryName'],
    template: `<div class="items-view">
                  <resetPasswordComponent></resetPasswordComponent>
                  <headerSectionComponent></headerSectionComponent>
                  <div class="section white">
                      <div class="container">
                          <template v-if="$store.getters.getAppState=='onload'">               
                                <div class="container center section white" style="min-height: 60%;" >
                                       <h3>{{tr('Page loading please wait')}}</h3>
                                </div>
                          </template>
                          <template v-else>
                                <!--<registerComponent></registerComponent>-->
                                <loginComponent></loginComponent>
                                <div class="row">
                                  <div class="col m3 s12 left sticky-component items-category-tree">
                                      <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                                      <div class="col m10 s12 sticky-component filters-section">
                                        <categorySectionComponent :categories=false></categorySectionComponent>
                                      </div>
                                  </div>                                 
                                  <div class="col m7 s12">
                                      <itemsSectionComponent :items="$store.items" :items_in_car="$store.items_in_car" :cat_history="$store.cat_history"></itemsSectionComponent>
                                  </div>
                                  <div class="col m2 s12 sticky-component right">
                                      <userSectionComponent></userSectionComponent>
                                      <carComponent></carComponent>
                                  </div>
                              </div>
                          </template>
                          
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
                </div>`,
    created:function(){
        this.selectCategory = this.selectCategory.bind(this);
    },
    mounted:function(){
        this.$nextTick(function () {
            console.log('appComponent');
            $(".dropdown-button").dropdown();
        });
    },
    methods: {
        loadCategories: function (root = null, lvl = 0) {
            this.$store.dispatch('loadCategories', root, lvl);
        },
        updateCategory: function (category) {
            this.$store.dispatch('updateCategory', category);
        },
        loadItems: function (category = null) {
            this.$store.dispatch('loadItems', category);
        },
        selectCategory: function (category) {
            if (category) {
                this.loadItems(category.code);
                this.$store.dispatch('updateCategory', category);
            }
            else {
                this.$store.dispatch('updateCategory', {children: []});
                this.loadItems();
            }
        }
    }
});
